import Link from 'next/link';

//Basic Link Layout, that defines a route, and a title, and returns a JSX element of a Link with a Title
const BasicLink = ({ route, title }) => {
	return (
		<Link passHref href={route}>
			<a className="country_links inline style_italic">{title}</a>
		</Link>
	);
};
export default BasicLink;
