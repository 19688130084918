import React from 'react';
import BaseLayout from '@/components/layouts/BaseLayout';
import Main from '@/pages/main';
// import CovidMain from '@/pages/covid_main';
// import CoronaTest from '@/pages/covid-test';
import MainTravelTest from '@/pages/main-t-test';
import Consultation from '@/pages/consultation';
import About from '@/pages/about';
import Contacts from '@/pages/contacts';
import Travels from '@/pages/travels';
// Translation Higher Order Component
import { translate } from 'react-switch-lang';
//Calls everything what is included in SuperComponent
const Index = ({ t }) => {
	return (
		//React.Fragment is used instead of <div> or <> to hide it from source
		<React.Fragment>
			{/* Base Layout - Shared component */}
			<BaseLayout
				className="cover"
				title={t('main.subtitle')}
				description={`${t('about.text2')} ${t('about.text3')}`}
				keywords={`${t('SEO.keywords_common')} ${t('SEO.keywords_main')}`}
				canonical="/"
			>
				<Main />
				<MainTravelTest />
				{/* <CovidMain /> */}
				{/* <CoronaTest /> */}
				<Consultation />
				<Travels />
				<About />
				<Contacts />
			</BaseLayout>
		</React.Fragment>
	);
};
export default translate(Index);
