import React from 'react';
import BasePage from '@/components/BasePage';
import Booking from '@/components/Booking';
import { Row, Col, Container } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Translation Higher Order Component
import { translate } from 'react-switch-lang';

const Consultation = ({ t }) => {
	return (
		//React.Fragment is used instead of <div> or <> to hide it from source
		<React.Fragment>
			{/* Base Page - Shared component with Container */}
			<BasePage id="consultation-page" className="consultation-page pt-5" role="article">
				<Container>
					<article>
						<Row>
							<Col className="text-center pt-5" md="12">
								<h2 className="font-weight-bold">
									<FontAwesomeIcon icon={[ 'fas', 'comment-medical' ]} /> &nbsp;{t('navbar.consultation')}
								</h2>
								<p className="page_subtext text-uppercase">{t('consultation.important')}</p>
								<hr className="vaccination-page_hr" />
							</Col>
						</Row>
						<Row>
							<Col md="6" className="consultation-page_text-container">
								<p className="float-left">{t('consultation.text1')}</p>
								<p className="float-left">{t('consultation.text2')}</p>
							</Col>
							<Col md="6" className="order-md-first consultation-page_img-container">
								<img
									width="520"
									height="347"
									className="shadowed-img img-fluid px-xs-5"
									src="/images/consultation.webp"
									alt="Beautiful young female doctor looking at camera in the office."
								/>
							</Col>
						</Row>
						<Booking />
					</article>
				</Container>
			</BasePage>
		</React.Fragment>
	);
};

export default translate(Consultation);
