import React from 'react';
import BasePage from '@/components/BasePage';
import Booking from '@/components/Booking';
import ContactForm from '@/components/ContactForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row, Col, Container, UncontrolledCollapse } from 'reactstrap';

// Translation Higher Order Component
import { translate } from 'react-switch-lang';

const Contacts = ({ t }) => {
	return (
		//React.Fragment is used instead of <div> or <> to hide it from source
		<React.Fragment>
			{/* Base Page - Shared component with Container */}
			<BasePage id="contacts-page" className="contacts-page">
				<Container>
					<section>
						<Row>
							<Col md="6">
								<h2 className="font-weight-bold">
									<FontAwesomeIcon icon={[ 'fas', 'address-book' ]} /> &nbsp;{t('contacts.title')}
								</h2>
								<p className="page_subtext text-uppercase">{t('contacts.subtitle')}</p>
								<hr className="vaccination-page_hr" />
								<p>
									{t('contacts.phone')}
									<a
										className="country_links float-right"
										title={`${t('topinfo.telephone')} +4554558965`}
										href="tel:+4554558965"
									>
										<span className="inline" itemProp="telephone">
											<FontAwesomeIcon icon={[ 'fas', 'phone' ]} />&nbsp;(+45) 54558965
										</span>
									</a>
								</p>
								<p>
									{t('contacts.adress')}
									<a
										className="country_links float-right"
										title={`${t('topinfo.location')}: Amager Landevej 31, 2770 Kastrup`}
										rel="external nofollow noopener"
										target="_blank"
										href="https://www.google.com/maps/place/Amager+Landevej+31,+2770+Kastrup/@55.6375594,12.6167232,17z/data=!3m1!4b1!4m5!3m4!1s0x4653ab59f87606ef:0x29e0296915c20f9a!8m2!3d55.6375594!4d12.6189119"
									>
										<span className="inline">
											<FontAwesomeIcon icon={[ 'fas', 'map-marker-alt' ]} /> &nbsp;
											<span itemProp="streetAddress">
												Amager Landevej 31
											</span>,<span itemProp="postalCode"> 2770</span>
										</span>
									</a>
								</p>
								<p>
									Instagram
									<a
										className="country_links float-right"
										title="Instagram Link: https://www.instagram.com"
										rel="external nofollow noopener"
										target="_blank"
										href=" https://www.instagram.com"
									>
										<span className="inline" itemProp="">
											<FontAwesomeIcon icon={[ 'fab', 'instagram' ]} /> &nbsp;
											<span>vaccin.dk.7</span>
										</span>
									</a>
								</p>
								<p>
									Facebook
									<a
										className="country_links float-right"
										title="Facebook Link: https://www.facebook.com/vaccin.dk.7/"
										rel="external nofollow noopener"
										target="_blank"
										href="https://www.facebook.com/vaccin.dk.7/"
									>
										<span className="inline" itemProp="">
											<FontAwesomeIcon icon={[ 'fab', 'facebook-square' ]} /> &nbsp;
											<span>vaccin.dk.7</span>
										</span>
									</a>
								</p>
								<br />
								<br />
								<div id="toggler" className="clickable">
									<p className="page_subtext inline">
										{t('contacts.write')}
										<a
											className="country_links inline"
											title="E-mail klinik@rcscanning.com"
											rel="external nofollow noopener"
											target="_blank"
											href="mailto:klinik@rcscanning.com"
										>
											<span className="float-right" itemProp="">
												<FontAwesomeIcon icon={[ 'fas', 'envelope' ]} /> &nbsp;
												<span>klinik@rcscanning.com </span>
											</span>
										</a>
									</p>
								</div>
								<UncontrolledCollapse toggler="#toggler">
									<ContactForm />
								</UncontrolledCollapse>
								<br />
								<br />
								<Booking />
							</Col>

							<Col md="6" className="order-md-first consultation-page_img-container">
								<img
									width="520"
									height="347"
									className="shadowed-img img-fluid px-xs-5"
									src="/images/contacts.webp"
									alt="Company Name logo"
								/>
							</Col>
						</Row>
					</section>
				</Container>
			</BasePage>
		</React.Fragment>
	);
};
export default translate(Contacts);
