import React from 'react';
import BasePage from '@/components/BasePage';
import BasicLink from '@/Handlers/BasicLink';
import { Container, Col, Row } from 'reactstrap';
// Translation Higher Order Component
import { translate } from 'react-switch-lang';

const MainTravelTest = ({ t }) => {
	return (
		<React.Fragment>
			<BasePage id="coronatest-page" className="coronatest_main-page">
				<Container>
					<article>
						<Row>
							<Col md="6" className="consultation-page_text-container covid">
								<h1 className="pt-5 font-weight-bold">{`COVID-19${t('navbar.covid')}`}</h1>
								<h3 className="page_subtext text-uppercase">{t('travel_certificate.title')}</h3>
								<hr className="vaccination-page_hr" />
								<p>{t('travel_certificate.text1')}</p>
								<p>{t('travel_certificate.text2')}</p>
								<BasicLink
									route="/travel-test"
									title={`${t('vaccines.read_more')}${t('vaccines.about')} COVID-19${t('navbar.covid')}`}
								/>
							</Col>
							<Col md="6" className="consultation-page_img-container shadowed">
								<img
									width="520"
									height="347"
									className="img-fluid px-xs-5"
									src="/images/travel_pass.webp"
									alt="Covid-19 Test"
								/>
								<svg height="0" width="0">
									<filter id="drop-shadow">
										<feGaussianBlur in="SourceAlpha" stdDeviation="2.2" />
										<feOffset dx="-50" dy="15" result="offsetblur" />
										<feFlood floodColor="rgba(172, 172, 172, 0.842)" />
										<feComposite in2="offsetblur" operator="in" />
										<feMerge>
											<feMergeNode />
											<feMergeNode in="SourceGraphic" />
										</feMerge>
									</filter>
								</svg>
							</Col>
						</Row>
					</article>
				</Container>
			</BasePage>
		</React.Fragment>
	);
};

export default translate(MainTravelTest);
